<template>
  <!--begin::Report Listing-->
  <ListingTemplate
    :customClass="'report-listing'"
    v-if="getPermission('report:view')"
  >
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <v-col cols="6" class="pt-0">
            <h1 class="form-title d-flex margin-auto">Reports</h1>
          </v-col>
        </template>
      </ListingHeader>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 75vh; position: relative"
      >
        <v-container fluid>
          <v-row v-if="favoriteList.length > 0" class="mb-4">
            <p class="font-weight-600 font-size-16 m-0 width-100 mx-6">
              My Favorites
            </p>
            <template v-for="(row, index) in favoriteList">
              <v-col md="3" class="py-0" :key="index">
                <v-list flat class="py-0">
                  <v-list-item>
                    <v-list-item-icon
                      class="mr-3 my-2"
                      link
                      v-on:click="markAsFavorite(row.value)"
                    >
                      <v-icon
                        v-if="isFavorite(row.value)"
                        color="yellow darken-3"
                        >mdi-star</v-icon
                      >
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                      class="py-2"
                      :content="row.text"
                      v-tippy="{ placement: 'top-start' }"
                      v-on:click="pushToDetail(row.value)"
                    >
                      <v-list-item-title
                        link
                        v-text="row.tooltip"
                        class="font-weight-500 font-size-14"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">
                  Customers by
                </p>
                <v-list-item v-for="(item, i) in customerList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">
                  Properties by
                </p>
                <v-list-item v-for="(item, i) in propertyList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">
                  Quotations by
                </p>
                <v-list-item v-for="(item, i) in quotationList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">Invoices by</p>
                <v-list-item v-for="(item, i) in invoiceList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">Payments by</p>
                <v-list-item v-for="(item, i) in paymentList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">Jobs by</p>
                <v-list-item v-for="(item, i) in jobList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">
                  Engineers by
                </p>
                <v-list-item v-for="(item, i) in engineerList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">Products by</p>
                <v-list-item v-for="(item, i) in productList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">Services by</p>
                <v-list-item v-for="(item, i) in serviceList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-list flat>
                <p class="font-weight-600 font-size-16 m-0 mx-3">
                  Warranties by
                </p>
                <v-list-item v-for="(item, i) in warrantyList" :key="i">
                  <v-list-item-icon
                    class="mr-3 my-2"
                    link
                    v-on:click="markAsFavorite(item.value)"
                  >
                    <v-icon
                      v-if="isFavorite(item.value)"
                      color="yellow darken-3"
                      >mdi-star</v-icon
                    >
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="py-2"
                    :content="item.tooltip"
                    v-tippy="{ placement: 'top-start' }"
                    v-on:click="pushToDetail(item.value)"
                  >
                    <v-list-item-title
                      link
                      v-text="item.text"
                      class="font-weight-500 font-size-14"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
  </ListingTemplate>
  <!--end::Report Listing-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "report",
  data() {
    return {
      favoriteArray: [],
      favoriteList: [],
      customerList: [
        {
          text: "Property",
          tooltip: "Customers by Property",
          value: "customers_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Customers by Quotation",
          value: "customers_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Customers by Invoice",
          value: "customers_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Customers by Payment",
          value: "customers_by_payment",
        },
        { text: "Job", tooltip: "Customers by Job", value: "customers_by_job" },
        {
          text: "Engineer",
          tooltip: "Customers by Engineer",
          value: "customers_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Customers by Product",
          value: "customers_by_product",
        },
        {
          text: "Service",
          tooltip: "Customers by Service",
          value: "customers_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Customers by Warranty",
          value: "customers_by_warranty",
        },
      ],
      propertyList: [
        {
          text: "Customer",
          tooltip: "Properties by Customer",
          value: "properties_by_customer",
        },
        {
          text: "Quotation",
          tooltip: "Properties by Quotation",
          value: "properties_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Properties by Invoice",
          value: "properties_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Properties by Payment",
          value: "properties_by_payment",
        },
        {
          text: "Job",
          tooltip: "Properties by Job",
          value: "properties_by_job",
        },
        {
          text: "Engineer",
          tooltip: "Properties by Engineer",
          value: "properties_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Properties by Product",
          value: "properties_by_product",
        },
        {
          text: "Service",
          tooltip: "Properties by Service",
          value: "properties_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Properties by Warranty",
          value: "properties_by_warranty",
        },
      ],
      quotationList: [
        {
          text: "Customer",
          tooltip: "Quotations by Customer",
          value: "quotations_by_customer",
        },
        {
          text: "Property",
          tooltip: "Quotations by Property",
          value: "quotations_by_property",
        },
        {
          text: "Invoice",
          tooltip: "Quotations by Invoice",
          value: "quotations_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Quotations by Payment",
          value: "quotations_by_payment",
        },
        {
          text: "Job",
          tooltip: "Quotations by Job",
          value: "quotations_by_job",
        },
        {
          text: "Engineer",
          tooltip: "Quotations by Engineer",
          value: "quotations_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Quotations by Product",
          value: "quotations_by_product",
        },
        {
          text: "Service",
          tooltip: "Quotations by Service",
          value: "quotations_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Quotations by Warranty",
          value: "quotations_by_warranty",
        },
      ],
      invoiceList: [
        {
          text: "Customer",
          tooltip: "Invoices by Customer",
          value: "invoices_by_customer",
        },
        {
          text: "Property",
          tooltip: "Invoices by Property",
          value: "invoices_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Invoices by Quotation",
          value: "invoices_by_quotation",
        },
        {
          text: "Payment",
          tooltip: "Invoices by Payment",
          value: "invoices_by_payment",
        },
        { text: "Job", tooltip: "Invoices by Job", value: "invoices_by_job" },
        {
          text: "Engineer",
          tooltip: "Invoices by Engineer",
          value: "invoices_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Invoices by Product",
          value: "invoices_by_product",
        },
        {
          text: "Service",
          tooltip: "Invoices by Service",
          value: "invoices_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Invoices by Warranty",
          value: "invoices_by_warranty",
        },
      ],
      paymentList: [
        {
          text: "Customer",
          tooltip: "Payments by Customer",
          value: "payments_by_customer",
        },
        {
          text: "Property",
          tooltip: "Payments by Property",
          value: "payments_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Payments by Quotation",
          value: "payments_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Payments by Invoice",
          value: "payments_by_invoice",
        },
        { text: "Job", tooltip: "Payments by Job", value: "payments_by_job" },
        {
          text: "Engineer",
          tooltip: "Payments by Engineer",
          value: "payments_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Payments by Product",
          value: "payments_by_product",
        },
        {
          text: "Service",
          tooltip: "Payments by Service",
          value: "payments_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Payments by Warranty",
          value: "payments_by_warranty",
        },
      ],
      jobList: [
        {
          text: "Customer",
          tooltip: "Jobs by Customer",
          value: "jobs_by_customer",
        },
        {
          text: "Property",
          tooltip: "Jobs by Property",
          value: "jobs_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Jobs by Quotation",
          value: "jobs_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Jobs by Invoice",
          value: "jobs_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Jobs by Payment",
          value: "jobs_by_payment",
        },
        {
          text: "Engineer",
          tooltip: "Jobs by Engineer",
          value: "jobs_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Jobs by Product",
          value: "jobs_by_product",
        },
        {
          text: "Service",
          tooltip: "Jobs by Service",
          value: "jobs_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Jobs by Warranty",
          value: "jobs_by_warranty",
        },
      ],
      engineerList: [
        {
          text: "Customer",
          tooltip: "Engineers by Customer",
          value: "engineers_by_customer",
        },
        {
          text: "Property",
          tooltip: "Engineers by Property",
          value: "engineers_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Engineers by Quotation",
          value: "engineers_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Engineers by Invoice",
          value: "engineers_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Engineers by Payment",
          value: "engineers_by_payment",
        },
        { text: "Job", tooltip: "Engineers by Job", value: "engineers_by_job" },
        {
          text: "Product",
          tooltip: "Engineers by Product",
          value: "engineers_by_product",
        },
        {
          text: "Service",
          tooltip: "Engineers by Service",
          value: "engineers_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Engineers by Warranty",
          value: "engineers_by_warranty",
        },
      ],
      productList: [
        {
          text: "Customer",
          tooltip: "Products by Customer",
          value: "products_by_customer",
        },
        {
          text: "Property",
          tooltip: "Products by Property",
          value: "products_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Products by Quotation",
          value: "products_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Products by Invoice",
          value: "products_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Products by Payment",
          value: "products_by_payment",
        },
        { text: "Job", tooltip: "Products by Job", value: "products_by_job" },
        {
          text: "Engineer",
          tooltip: "Products by Engineer",
          value: "products_by_engineer",
        },
        {
          text: "Service",
          tooltip: "Products by Service",
          value: "products_by_service",
        },
        {
          text: "Warranty",
          tooltip: "Products by Warranty",
          value: "products_by_warranty",
        },
      ],
      serviceList: [
        {
          text: "Customer",
          tooltip: "Services by Customer",
          value: "services_by_customer",
        },
        {
          text: "Property",
          tooltip: "Services by Property",
          value: "services_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Services by Quotation",
          value: "services_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Services by Invoice",
          value: "services_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Services by Payment",
          value: "services_by_payment",
        },
        { text: "Job", tooltip: "Services by Job", value: "services_by_job" },
        {
          text: "Engineer",
          tooltip: "Services by Engineer",
          value: "services_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Services by Product",
          value: "services_by_product",
        },
        {
          text: "Warranty",
          tooltip: "Services by Warranty",
          value: "services_by_warranty",
        },
      ],
      warrantyList: [
        {
          text: "Customer",
          tooltip: "Warranties by Customer",
          value: "warranties_by_customer",
        },
        {
          text: "Property",
          tooltip: "Warranties by Property",
          value: "warranties_by_property",
        },
        {
          text: "Quotation",
          tooltip: "Warranties by Quotation",
          value: "warranties_by_quotation",
        },
        {
          text: "Invoice",
          tooltip: "Warranties by Invoice",
          value: "warranties_by_invoice",
        },
        {
          text: "Payment",
          tooltip: "Warranties by Payment",
          value: "warranties_by_payment",
        },
        {
          text: "Job",
          tooltip: "Warranties by Job",
          value: "warranties_by_job",
        },
        {
          text: "Engineer",
          tooltip: "Warranties by Engineer",
          value: "warranties_by_engineer",
        },
        {
          text: "Product",
          tooltip: "Warranties by Product",
          value: "warranties_by_product",
        },
        {
          text: "Service",
          tooltip: "Warranties by Service",
          value: "warranties_by_service",
        },
      ],
    };
  },
  components: {
    ListingTemplate,
    ListingHeader,
  },
  methods: {
    pushToDetail(value) {
      this.$router.push(
        this.getDefaultRoute("report.detail", {
          params: { module: value.replaceAll("_", "-") },
        })
      );
    },
    markAsFavorite(value) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "report/favorite/" + value,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getFavorites();
        });
    },
    isFavorite(value) {
      const _this = this;
      if (_this.lodash.includes(_this.favoriteArray, value)) {
        return true;
      }
      return false;
    },
    refreshFavorites() {
      const _this = this;
      let listArray = _this.lodash.concat(
        _this.customerList,
        _this.propertyList,
        _this.quotationList,
        _this.invoiceList,
        _this.paymentList,
        _this.jobList,
        _this.engineerList,
        _this.productList,
        _this.serviceList,
        _this.warrantyList
      );

      _this.favoriteList = [];

      for (let i = 0; i < listArray.length; i++) {
        if (_this.lodash.includes(_this.favoriteArray, listArray[i].value)) {
          _this.favoriteList.push(listArray[i]);
        }
      }
    },
    getFavorites() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "report/favorite",
        })
        .then(({ data }) => {
          _this.favoriteArray = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.refreshFavorites();
        });
    },
  },
  mounted() {
    this.getFavorites();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Report",
      },
    ]);
  },
};
</script>
